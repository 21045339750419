<template>
  <div>
    <h1 class="font-semibold text-xl mb-5 flex justify-between items-center">
      Магазины
    </h1>
    <el-table stripe border size="small" v-loading="loading" :data="shops">
      <el-table-column type="index" label="N" align="center"></el-table-column>
      <el-table-column prop="company" label="Компания"></el-table-column>
      <el-table-column prop="merchant_id" label="Merchant ID"></el-table-column>
      <el-table-column prop="token" label="Токен"></el-table-column>
      <el-table-column prop="token" label="Ссылка на прайс">
        <template v-slot="scope">
          <a
            target="_blank"
            class="text-blue-500 underline"
            :href="`https://api.alasal.kz/xml/kaspi/${scope.row.id}`"
            >Перейти</a
          >
        </template>
      </el-table-column>
    </el-table>

    <h1
      class="mt-5 font-semibold text-xl mb-5 flex justify-between items-center"
    >
      Точки продаж ({{ branches.length }})
      <el-button @click="openCreateBranchDialog" size="small" type="primary">
        Создать
      </el-button>
    </h1>

    <el-table stripe border size="small" v-loading="loading" :data="branches">
      <el-table-column prop="company" label="Магазин"></el-table-column>
      <el-table-column prop="name" label="Название"></el-table-column>
      <el-table-column prop="warehouse_name" label="Склад"></el-table-column>
      <el-table-column prop="code" label="storeId"></el-table-column>
      <el-table-column prop="city_id" label="Город">
        <template slot-scope="scope">
          {{ getCityTitle(scope.row.city_id) }}
        </template>
      </el-table-column>
      <el-table-column prop="is_main" align="center" label="Главный пункт">
        <template slot-scope="scope">
          <i
            v-if="scope.row.is_main"
            class="el-icon-success text-green-500"
          ></i>
        </template>
      </el-table-column>
      <el-table-column align="right" prop="brand" label="Действия">
        <template slot-scope="scope">
          <el-button
            @click.stop="selectBranch(scope.row)"
            size="small"
            plain
            icon="el-icon-edit"
            type="primary"
          ></el-button>
          <el-button
            @click.stop="deleteBranch(scope.row.id)"
            size="small"
            plain
            icon="el-icon-delete"
            type="danger"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="Точка продаж" :visible.sync="dialogVisible" width="30%">
      <el-form label-position="top">
        <el-form-item label="Склад">
          <el-select filterable v-model="branch.warehouse_id">
            <el-option
              v-for="wh in warehouses"
              :key="wh.id"
              :value="wh.id"
              :label="wh.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Магазин">
          <el-select filterable v-model="branch.shop_id">
            <el-option
              v-for="wh in shops"
              :key="wh.id"
              :value="wh.id"
              :label="wh.company"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="StoreId">
          <el-input v-model="branch.code"></el-input>
        </el-form-item>
        <el-form-item label="Название">
          <el-input v-model="branch.name"></el-input>
        </el-form-item>
        <el-form-item label="Главный пункт?">
          <el-checkbox v-model="branch.is_main"></el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button
            :disabled="loading"
            size="small"
            @click="dialogVisible = false"
          >
            Отмена
          </el-button>
          <el-button
            :disabled="loading"
            type="primary"
            size="small"
            @click="save"
          >
            Сохранить
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getShops } from "@/api/branch";

import {
  getBranches,
  createBranch,
  updateBranch,
  deleteBranch,
} from "@/api/branch";
import { getWarehouses } from "@/api/accounting";
import { getCities } from "@/api/branch";

const emptyBranchData = {
  id: null,
  warehouse_id: null,
  shop_id: null,
  code: null,
  name: null,
  is_main: false,
};

export default {
  name: "index",
  data() {
    return {
      loading: false,
      shops: [],
      cities: [],
      branches: [],
      warehouses: [],
      dialogVisible: false,
      branch: Object.assign({}, {}, emptyBranchData),
    };
  },
  async created() {
    this.shops = await getShops();
    await this.getBranches();
    await this.getCities();
    this.warehouses = await getWarehouses();
  },
  methods: {
    async getBranches() {
      this.loading = true;
      this.branches = await getBranches();
      this.loading = false;
    },
    async getCities() {
      this.cities = await getCities();
    },
    openCreateBranchDialog() {
      this.dialogVisible = true;
      this.branch = Object.assign({}, this.branch, emptyBranchData);
    },
    async save() {
      if (this.branch.id) {
        await this.updateBranch();
      } else {
        await this.createBranch();
      }
    },
    async createBranch() {
      this.loading = true;
      try {
        const result = await createBranch(this.branch);
        if (result.success) {
          this.$message.success("Успешно");
          this.dialogVisible = false;
          await this.getBranches();
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(e.message);
      }
      this.loading = false;
    },
    async updateBranch() {
      this.loading = true;
      try {
        const result = await updateBranch(this.branch.id, this.branch);
        if (result.success) {
          this.$message.success("Успешно");
          this.dialogVisible = false;
          await this.getBranches();
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(e.message);
      }
      this.loading = false;
    },
    selectBranch(branch) {
      console.log(branch);
      this.dialogVisible = true;
      this.branch = Object.assign({}, this.branch, branch);
    },
    async deleteBranch(id) {
      this.$confirm(
        "Вы действительно хотите удалить. Продолжить?",
        "Внимание",
        {
          confirmButtonText: "Да",
          cancelButtonText: "Отмена",
          type: "warning",
        }
      ).then(async () => {
        await deleteBranch(id);
        await this.getBranches();
      });
    },
    getCityTitle(id) {
      if (this.cities.length > 0) {
        return this.cities.find((c) => c.id === id).name;
      }
      return null;
    },
  },
};
</script>

<style scoped></style>
