import apiClient from "@/api/apiClient";

export const getPaymentTypes = async () => {
  const result = await apiClient.get("/api/payment_types");
  return result.data.payment_types;
};

export const getChannels = async () => {
  const result = await apiClient.get("/api/channels");
  return result.data.channels;
};

export const getWarehouses = async () => {
  const result = await apiClient.get("/api/warehouses");
  return result.data.warehouses;
};

export const getWarehouseMissingProducts = async (warehouse_id) => {
  const result = await apiClient.get("/api/warehouses/missing-products", {
    params: {
      warehouse_id,
    },
  });
  return result.data.missing_products;
};

export const getAccountings = async (filters) => {
  const result = await apiClient.get("/api/accounting", {
    params: filters,
  });
  return result.data;
};

export const getAccounting = async (id) => {
  const result = await apiClient.get(`/api/accounting/${id}`);
  return result.data;
};

export const getAccountingWaybill = async (basis) => {
  try {
    const result = await apiClient.get(`/api/accounting/${basis}/waybill`);
    return result.data;
  } catch (e) {
    return { success: false };
  }
};

export const createAccounting = async (data) => {
  const result = await apiClient.post("/api/accounting", data);
  return result.data;
};

export const updateAccounting = async (accountingId, data) => {
  const result = await apiClient.put(`/api/accounting/${accountingId}`, data);
  return result.data;
};

export const checkAccountingStatus = async (accountingId) => {
  const result = await apiClient.post(`/api/accounting/${accountingId}`);
  return result.data;
};

export const removeAccounting = async (accountingId) => {
  const result = await apiClient.delete(`/api/accounting/${accountingId}`);
  return result.data;
};

export const replaceAccountingItems = async (accountingId) => {
  const result = await apiClient.post(`/api/accounting/${accountingId}`, {
    action: "replace",
  });
  return result.data;
};

export const getOrderClient = async (orderCode) => {
  const result = await apiClient.get(`/api/accounting/orders/${orderCode}`);
  return result.data;
};

export const importReviews = async (fileUrl) => {
  const result = await apiClient.post(`/api/reviews`, { file_url: fileUrl });
  return result.data;
};

export const importSulpak = async (fileUrl) => {
  const result = await apiClient.post(`/api/sulpak`, { file_url: fileUrl });
  return result.data;
};

export const getSulpakProducts = async (filters) => {
  const result = await apiClient.get(`/api/sulpak`, {
    params: filters,
  });
  return result.data;
};

export const linkProducts = async (productId, { action, kaspi_sku, price }) => {
  const result = await apiClient.post(`/api/sulpak/${productId}`, {
    action,
    kaspi_sku,
    price,
  });
  return result.data;
};
